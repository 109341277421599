<template>
	<div class="app">
		<test></test>
		<div class="banner">
			<div class="item">
				<img :src="dataList[currentIndex]">
			</div>
			<div class="page" v-if="this.dataList.length > 1">
				<ul>
					<li v-for="(item,index) in dataList" @click="gotoPage(index)"
						:class="{'current':currentIndex == index}"></li>
				</ul>
			</div>
		</div>
		<div class="abot">
			<h2>用数字普惠金融成就美好生活</h2>
			<p>山东国晟小额贷款有限责任公司（简称国晟普惠）是经中国人民银行及中国银行保险监督管理委员会审核，青岛市地方金融监督管理局批复成立的全省首家全国性（线上）网络小贷公司，总部设在青岛。<br>
			公司开创了山东省地方金融新业态，填补了互联网小贷行业发展空白。<br>
			我们始终践行普惠金融理念，通过产品创新和服务升级，为普惠用户提供一体化的金融服务。<br>
			</p>
			<span><router-link to='/about'>了解更多></router-link></span>
		</div>
		<div class="news">
			<h2>企业要闻</h2>
			<img src="./images/zp_18.png" style="width: 60px;" >
			<div class="new_txt">
				<div class="new_l">
					<img :src="details.contentImg">
					<span>企业新闻/{{details.contentDatetime}}</span>
					<h5  @click="detail(details.id)">{{details.contentTitle}}</h5>
					<p style="line-height: 1.5;">{{details.contentDescription}}</p>
				</div>
				<div class="new_r">
					<ul>
						<li v-for="item in contlist.slice(0,5)" @click="detail(item.id)">
							<span>企业新闻/{{item.contentDatetime}}</span>
							<h5>{{item.contentTitle}}</h5>
						</li>
					</ul>
					<span><router-link to='/Notice' style="color: #2A4D90;">了解更多></router-link></span>
				</div>
			</div>
		</div>
		<div class="cooperation">
			<h2>合作伙伴</h2>
			<img src="./images/zp_18.png" style="width: 60px;" >
			<ul>
				<li><img src="./images/index_07.png" ></li>
				<li><img src="./images/index_09.png" ></li>
				<li><img src="./images/index_11.png" ></li>
				<li><img src="./images/index_13.png" ></li>
				<li><img src="./images/index_15.png" ></li>
				<li><img src="./images/index_17.png" ></li>
				<li><img src="./images/index_19.png" ></li>
				<li><img src="./images/index_20.png" ></li>
				<li><img src="./images/index_21.png" ></li>
				<li><img src="./images/index_22.png" ></li>
				<li><img src="./images/index_23.png" ></li>
				<li><img src="./images/index_24.png" ></li>
			</ul>
		</div>
		<foot></foot>
	</div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default{
	components: {
		test,
		foot
	},
	data(){
		return{
			details:'',
			contlist:[],
			dataList:[],
			// dataList:["https://i1.mifile.cn/a4/xmad_15535933141925_ulkYv.jpg","https://i1.mifile.cn/a4/xmad_15532384207972_iJXSx.jpg","https://i1.mifile.cn/a4/xmad_15517939170939_oiXCK.jpg"],
			currentIndex: 0,   //默认显示图片
			timer: null    //定时器
		}
	},
	created() {
		if (location.href.indexOf("#reloaded") == -1) {
			location.href = location.href + "#reloaded";
			location.reload();
		}
		var that = this
		//轮播图
		this.$axios.post('/cms/banner/officialWebsite')
		.then(function (res) {
			//console.log(res);
			that.dataList = res.data.imgList
		})

		//新闻列表
		this.$axios.post('/cms/returnAll/officialWebsite')
		.then(function (res) {
			//console.log(res);
			that.contlist = res.data.categoryList[3].categoryChildrenList[0].contentList
			//that.details = res.data.categoryList[3].categoryChildrenList[0].contentList[0]
		})
		//新闻左栏
		this.$axios.get('/cms/returnSuggestion/officialWebsite')
		.then(function (res) {
			//console.log(res,'图文');
			that.details = res.data

		})
		that.runInv();

	},
	methods: {
		detail(id) {
			this.$router.push({
				path: '/Notice_detail',
				query: {
					id: id,
				}

			})
		},
		gotoPage(index) {
			this.currentIndex = index;
		},
	//定时器
	runInv() {
		this.timer = setInterval(() => {
			this.gotoPage(this.nextIndex)
		}, 3000)
	}
	},
	computed: {
		//上一张
		prevIndex() {
			if (this.currentIndex == 0) {
				return this.dataList.length - 1;
			} else {
				return this.currentIndex - 1;
			}
		},
		//下一张
		nextIndex() {
			if (this.currentIndex == this.dataList.length - 1) {
				return 0;
			} else {
				return this.currentIndex + 1;
			}
		}
	}

}
</script>

<style>
.cooperation ul{
	width: 65%;
	margin: 50px auto;
	display: flex;
	justify-content: center;
	flex-flow: row wrap;
}
.cooperation ul li{
	width: 15%;
	margin-right: 1%;
}
.cooperation ul li img{
	width: 80%;
}
.cooperation h2{
	font-weight: 500;
}
.new_txt .new_r li{
	cursor:pointer;
	margin-bottom: 50px;
}
.new_txt .new_r span{
	color: #2a4d90;
	font-size: 14px;
}
.new_txt .new_r li span{
	font-size:12px;
	color: #666;
	display: block;
}
.new_txt .new_r{
	flex: 2;
	text-align: left
}
.new_txt .new_r h3{
	color: #666;
}
.new_txt .new_l img{
	width: 100%;
	margin-bottom: 20px;
}
.new_txt .new_l span{
	margin-left: 20px;
	color: #888;
	font-size: 12px;
}
.new_txt .new_l{
	padding-bottom: 30px;
	flex: 2;
	margin-right: 5%;
	background-color: #f3f3f3;
	text-align: left;
}
.new_txt .new_r h5{
	text-align: left;
	margin-top: 20px;
	margin-bottom: 10px;
	color: #555;
	font-size: 16px;
	font-weight: 500;
}
.new_txt .new_l p{
	padding: 20px;
	font-size: 14px;
	color: #333;
}
.new_txt .new_l h5{
	cursor: pointer;
	text-align: left;
	margin-top: 20px;
	margin-left: 20px;
	margin-right: 10px;
	color: #555;
	font-size: 16px;
	font-weight: 500;
}
.new_txt{
	width: 65%;
	margin: 40px auto;
	display: flex;
	justify-content: center;
}
.news h2{
	font-weight: 500;
}
.news{
	padding: 5% 0;
}
.bus_main>img{
	width: 50px;
}
.bus_main ul li .txt{
	margin-top: 200px;
	text-align: left;
	height: 120px;
	/* border:1px solid #f4f6f8; */
	padding:0 4%;
}
.bus_main ul li .txt button a{
	color: #ea0029;
}
.bus_main ul li .txt button{
	margin-top: 20px;
	font-size: 13px;
	border: 1px solid #ea0029;
	background-color: #fff;
	border-radius: 30px;
	color: #ea0029;
	padding: 10px 25px;
	position: absolute;
	bottom: 20px;
}
.bus_main>ul li .txt h4{
	font-weight: normal;
	font-size: 18px;
	color: #3B3B3B;
}
.bus_main>ul li .text{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	color: #fff;
	padding: 7%;
	text-align: left;
}
.bus_main>ul li .text p{
	color: #fff;
}
.bus_main>ul li .text h3{
	font-weight: normal;
	color: #fff
}
.bus_main>ul li .text h1{
	margin-top: 10px;
}
.bus_main{

	/* width: 1200px; */
	margin: 40px auto;
}
.bus_main>h3{
	margin: 0;
	color: #333
}

.bus_main>ul{
	width: 100%;
	margin-bottom: 60px;
	margin: 30px auto;
	display: flex;
	justify-content: center;
}
.bus_main>ul li{
	background-color: #fff;
	position: relative;
	flex: 2;
	margin: 2%;
	padding-bottom: 20px;
}
.bus_main ul li img{
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 8;
	height: 190px;
}
.bus_main>ul li .bg{
	width:100%;
	height: 190px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}
.bus_main>ul li:first-child .bg{
	background:rgba(0,149,255,0.5);
}
.bus_main>ul li:nth-child(2) .bg{
	background:rgba(255,53,0,0.5);
}
.bus_main>ul li:nth-child(3) .bg{
	background:rgba(255,3,18,0.5);
}

.bus_main p{
	color: #666;
	line-height: 2;
	font-size: 13px;
	margin-top: 20px;
}
.business  img{
	width: 60px;
}
.business h2{
	font-weight: 500;
}
.business{
	width: 100%;
	background-color: #f3f3f3;
	padding: 5% 0;
}
.bus_main{
	width: 65%;
	margin: 0 auto;
}
.abot span a{
	color: #2a4d90;
	font-size: 12px;
}
.abot p{
	text-align: center;
	line-height: 2;
	width: 65%;
	margin: 0 auto;
	font-size: 13px;
	padding: 40px 0;
}
.abot h2{
	font-weight: 300;
	color: #666;
	font-size: 30px;
	margin-bottom: 20px;
}
.abot{
	padding: 5% 0;
}
.page ul li.current{
	background: rgba(0,0,0,0.3);
}
.page ul{
	padding: 10px 0;
}
.page ul li {
	margin-right: 10px;
	list-style: none;
	float: left;
	border-radius: 20px;
	width: 8px;
	height: 8px;
	cursor: pointer;
	background-color: #fff;
	font-size: 14px;
}
.banner {
	/* max-width: 1200px; */
	margin: 0 auto;
	position: relative;
	width: 100%;
}
.banner img {
	height: auto;
	width: 100%;
	display: block;
}
.banner .page {
	margin-bottom: 20px;
	/* background: rgba(0,0,0,.5); */
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.banner .page ul {
	float: right;
}
.current {
	color: #ff6700;
}

</style>
